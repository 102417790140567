<template>
  <div class="personalAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'人员详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="infoBox"
      v-watermark
    >
      <van-cell-group>
        <van-cell
          title="头像:"
          center
        >
          <template #default>
            <span class="userPic">
              {{ userInfo.firstName || "--" }}
            </span>
          </template>
        </van-cell>
        <van-cell
          title="姓名:"
          :value="userInfo.userName || '--'"
          center
        />
        <van-cell
          title="性别:"
          :value="userInfo.gender || '--'"
          center
        />
        <van-cell
          title="单位:"
          center
        >
          <template #default>
            <p
              :style="{ color: '#1990ff' }"
              v-if="userInfo.companyName"
              @click="mapSelect"
            >
              {{ userInfo.companyName }}
            </p>
            <p v-else>--</p>
          </template>
        </van-cell>
        <van-cell
          title="部门:"
          :value="userInfo.departmentName || '--'"
          center
        />
        <van-cell
          title="岗位:"
          :value="userInfo.positionName || '--'"
          center
        />
        <van-cell
          title="手机:"
          center
        >
          <template #default>
            <p
              :style="{ color: '#1990ff' }"
              v-if="userInfo.mobile"
              @click="callOut(userInfo.mobile)"
            >
              {{ userInfo.mobile }}
            </p>
            <p v-else>--</p>
          </template>
        </van-cell>
        <van-cell
          title="短号:"
          center
        >
          <template #default>
            <p
              :style="{ color: '#1990ff' }"
              v-if="userInfo.shortPhone"
              @click="callOut(userInfo.shortPhone)"
            >
              {{ userInfo.shortPhone }}
            </p>
            <p v-else>--</p>
          </template>
        </van-cell>
        <van-cell
          title="邮箱:"
          center
        >
          <template #default>
            <p
              :style="{ color: '#1990ff' }"
              v-if="userInfo.email"
              @click="sendEmail(userInfo.email)"
            >
              {{ userInfo.email }}
            </p>
            <p v-else>--</p>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/addressBook'
import { getMapUrl } from '@/api/map'
import { Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import { getAddressInfo } from '@/api/system'

export default {
  name: 'personalAddressInfo',
  data() {
    return {

      userInfo: {},
      loadingShow: false
    }
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getUserInfo(this.$route.params.id)
      if (data.data) {
        if (data.data.status === 0 || data.data.status === 2) {
          this.userInfo = data.data
          wx.onMenuShareAppMessage({
            title: this.userInfo.userName, // 分享标题
            desc: `手机:${this.userInfo.mobile ? this.userInfo.mobile : '--'
              }\n短号:${this.userInfo.shortPhone ? this.userInfo.shortPhone : '--'
              }\n部门:${this.userInfo.departmentName ? this.userInfo.departmentName : '--'
              }`, // 分享描述
            link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
            imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            error: function (res) {
              alert('暂无分享权限')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
          this.loadingShow = false
        } else {
          this.loadingShow = false
          this.$toast.fail({
            message: '当前用户不存在，请联系管理员!',
            duration: 500
          })
        }
      } else {
        this.loadingShow = false
        this.$toast.fail({
          message: '当前用户不存在，请联系管理员!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      if (error.response.data.status === 'E00030010') {
        this.$toast.fail({
          message: '当前用户不存在，请联系管理员!',
          duration: 500
        })
        return
      }
      if (error.response.data.status === 'E00030011') {
        this.$toast.fail({
          message: '当前用户不存在，请联系管理员!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  methods: {
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息提示',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 选择地图定位
    async mapSelect() {
      const value = /wxwork/i.test(navigator.userAgent)
      const flag =
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ) != null
      if (value && flag) {
        const { data: { data } } = await getAddressInfo(this.userInfo.companyAddress)
        if (data.location) {
          const location = data.location
          wx.openLocation({
            latitude: location.lat, // 纬度，浮点数，范围为90 ~ -90
            longitude: location.lng, // 经度，浮点数，范围为180 ~ -180。
            name: this.userInfo.companyName, // 位置名
            address: this.userInfo.companyAddress, // 地址详情说明
            scale: 16 // 地图缩放级别,整形值,范围从1~28。默认为16
          })
        } else {
          const { data } = await getMapUrl('amap', this.userInfo.companyAddress)
          window.location.href = data.data
        }
      } else {
        const { data } = await getMapUrl('amap', this.userInfo.companyAddress)
        window.location.href = data.data
      }
    },
    // 发送邮件
    sendEmail(val) {
      window.location = `mailto:${val}`
    }
  }
}
</script>

<style lang="scss" scoped>
.personalAddressInfo {
  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .infoBox {
      height: calc(100vh - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;

      .van-cell-group {
        .van-cell {
          .van-cell__value {
            flex: none;
          }
        }
      }
    }

    .userPic {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      display: inline-block;
      background-color: #1990ff;
      color: white;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
    }
  }
}
</style>
